import React from "react";
import BlogCard from "../components/blogCard";
import Layout from "../components/layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AboutHero from "../components/about-hero";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import PackageHero from "../components/package-hero";
import { Button } from "react-bootstrap";
import CtaBanner from "../components/cta-banner";

const PackagesPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				heroImg: wpMediaItem(title: { eq: "Packages-Hero" }) {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
				blogPosts: allWpPost(
					sort: { fields: dateGmt, order: DESC }
					filter: { title: { ne: "Website Images" } }
				) {
					nodes {
						date(formatString: "MMMM DD, YYYY")
						id
						title
						uri
						excerpt
						blogFields {
							featuredImage {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData
									}
								}
							}
							type
						}
					}
				}
			}
		`
	);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const heroImg = data.heroImg.localFile.childImageSharp.gatsbyImageData;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Copy Strategy Packages",
				item: {
					url: `${siteUrl}/copy-strategy-packages`,
					id: `${siteUrl}/copy-strategy-packages`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title="Copy strategy packages | ARC Writing and Translation Services"
				description="Are you looking for regular, fresh content for your website and social media to boost your SEO and build your audience? Choose from one of my packages."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/copy-strategy-packages`,
					title:
						"Copy Strategy Packages | ARC Writing and Translation Services",
					description:
						"Are you looking for regular, fresh content for your website and social media to boost your SEO and build your audience? Choose from one of my packages.",
					images: [
						{
							url: `https://arc-writing.rjmdigital.net/wp-content/uploads/2021/11/j3R3HV6g-scaled-1-683x1024-1.jpg`,
							width: 683,
							height: 1024,
							alt: "ARC Writing and Translation Services blog page",
						},
					],
				}}
			/>
			<section>
				<PackageHero
					h="h-100 h-md-85"
					imgWidth="25rem"
					hCenter={true}
					title={
						<span className=" mt-4 mt-md-0 ms-lg-5 ms-xl-0 underline">
							Packages
						</span>
					}
					description={
						<span className="">
							<p className="normal-weight ms-lg-5 ms-xl-0 mt-4">
								Are you looking for regular, fresh content for your website and
								social media to boost your SEO and build your audience?
							</p>
							<p className="normal-weight ms-lg-5 ms-xl-0">
								Then why not choose from one of my packages?
							</p>
							<p className="ms-lg-5 ms-xl-0">
								I offer a range of ghostwriting and copywriting packages to help
								you get your message across, boost your brand image and elevate
								your business through targeted copywriting and ghostwriting.
							</p>
							<Row className="justify-content-start  my-4">
								<Col className="text-start" md={9} lg={6}>
									<Button
										className="cta-btn w-100 ms-lg-5 ms-xl-0 py-2"
										variant="secondary"
										as={Link}
										to="/contact"
									>
										Contact
									</Button>
								</Col>
							</Row>
						</span>
					}
					image={heroImg}
					imgAlt={data.heroImg.altText}
				/>
			</section>
			<section>
				<Container>
					<Row>
						<Col>
							<div className="text-md-center mt-5">
								<h2 className="underline d-none d-md-inline-block  mb-4 fs-2 fs-lg-1 ">
									Ghostwriting packages
								</h2>
								<h2 className=" mb-3  d-md-none">Ghostwriting packages</h2>
							</div>
							<p>
								Both of my ghostwriting packages comprise the ghostwriting of
								your non-fiction book containing up to 40,000 words. The only
								difference between the packages is that with the Polished Prose
								package, I will hire a trusted external copy editor for you so
								that the final manuscript you receive is ready for publishing.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5 py-lg-7">
				<Container>
					<Row className="h-100 justify-content-center g-5 g-lg-4">
						<Col style={{ minHeight: "100%" }} lg={4}>
							<div
								className="w-100 bg-white h-100 p-4 position-relative"
								style={{ boxShadow: "0px 3px 20px #00000029" }}
							>
								<h2 className="pb-0 mb-1 fs-3 text-uppercase">
									Whispered Words
								</h2>
								<hr
									className="w-100 mt-0 pt-0"
									style={{ opacity: 1, backgroundColor: "#C157A1" }}
								/>
								<ul
									style={{ fontWeight: "600" }}
									className="copy-list ps-0  py-4"
								>
									<li>Research and interviews</li>
									<li>Book outline and content development</li>
									<li>Iterative writing and feedback</li>
								</ul>
								<div
									className="d-none d-lg-block"
									style={{ height: "150px" }}
								></div>
								<div className=" position-absolute d-none d-lg-block bottom-0  px-4 w-100 start-50 translate-middle-x">
									<hr
										className="w-100 mt-0 pt-0 mb-4"
										style={{ opacity: 0.7, backgroundColor: "#00000029" }}
									/>
									<p className="fatface pb-5 text-center">
										<span className="fs-2">£10000</span>
										<span style={{ color: "#3D3D3D76" }} className="fs-4">
											.00 + VAT
										</span>
									</p>
								</div>
								<div className="d-lg-none">
									<hr
										className="w-100 mt-0 pt-0 mb-4"
										style={{ opacity: 0.7, backgroundColor: "#00000029" }}
									/>
									<p className="fatface pb-3 text-center">
										<span className="fs-2">£10000</span>
										<span style={{ color: "#3D3D3D76" }} className="fs-4">
											.00 + VAT
										</span>
									</p>
								</div>
							</div>
						</Col>
						<Col style={{ minHeight: "100%" }} lg={4}>
							<div
								className="w-100 bg-white h-100 p-4 position-relative"
								style={{ boxShadow: "0px 3px 20px #00000029" }}
							>
								<h2 className="pb-0 mb-1 fs-3 text-uppercase">
									Polished
									<br className="d-none d-lg-block d-xl-none" /> Prose
								</h2>
								<hr
									className="w-100 mt-0 pt-0"
									style={{ opacity: 1, backgroundColor: "#C157A1" }}
								/>
								<ul
									style={{ fontWeight: "600" }}
									className="copy-list ps-0  py-4"
								>
									<li>Research and interviews</li>
									<li>Book outline and content development</li>
									<li>Iterative writing and feedback</li>
									<li>Editing by a copy editor</li>
								</ul>
								<div className=" position-absolute d-none d-lg-block bottom-0  px-4 w-100 start-50 translate-middle-x">
									<hr
										className="w-100 mt-0 pt-0 mb-4"
										style={{ opacity: 0.7, backgroundColor: "#00000029" }}
									/>
									<p className="fatface pb-5 text-center">
										<span className="fs-2">£12500</span>
										<span style={{ color: "#3D3D3D76" }} className="fs-4">
											.00 + VAT
										</span>
									</p>
								</div>
								<div className="d-lg-none">
									<hr
										className="w-100 mt-0 pt-0 mb-4"
										style={{ opacity: 0.7, backgroundColor: "#00000029" }}
									/>
									<p className="fatface pb-3 text-center">
										<span className="fs-2">£12500</span>
										<span style={{ color: "#3D3D3D76" }} className="fs-4">
											.00 + VAT
										</span>
									</p>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<CtaBanner
				headline={
					<>Click here to find out more about my ghostwriting services</>
				}
				bgColor="dark-background"
				btnTxt="Find out more"
				btnLink="/services/ghostwriting"
			/>
			<section>
				<Container>
					<Row>
						<Col>
							<div className="text-md-center mt-5">
								<h2 className="underline d-none d-md-inline-block  mb-4 fs-2 fs-lg-1 ">
									Annual strategy package
								</h2>
								<h2 className=" mb-3  d-md-none">Annual strategy package</h2>
							</div>
							<p>
								As a busy business owner, I know how easy it is to take your eye
								off the ball when it comes to your copy and let old blog posts
								languish on your website desperately longing for a more recent
								blog to take their place or put off writing a case study or
								refreshing your website because you have more pressing things to
								do. Meanwhile, your competitors are getting ahead of the curve
								with engaging social media posts, a shiny new website fit for
								2023 featuring regular blogs and up-to-date landing pages to
								promote their latest offering.{" "}
							</p>
							<p>
								To help you get this necessary but time-consuming task off of
								your to-do list and out into the marketplace where it can start
								working for you I have come up with an easy and affordable
								solution that ensures your written content is on-brand and
								up-to-date all year round by developing an annual copy strategy.
							</p>
							<h3>How does it work?</h3>
							<ul style={{ fontWeight: "600" }} className="copy-list ps-md-4 ">
								<li>
									Free 30-minute discovery call to see if we are a good fit for
									each other
								</li>
								<li>
									Initial 1-hour Zoom call to discuss your company, client
									avatar, tone of voice, competitors, your industry, seasonal
									adjustments, busy periods, what’s coming down the pipe (new
									products/services) and your analytics
								</li>
								<li>
									4 x 1-hour Zoom calls each quarter to plan your copy strategy
									for the next quarter
								</li>
								<li>Content of your choice written each quarter*</li>
								<li>Up to 5 hours/month to work on ad hoc copy needs**</li>
							</ul>
							<p className="mb-0 pb-0" style={{ fontSize: "90%" }}>
								*Only available with the Advanced and Trailblazer packages
							</p>
							<p style={{ fontSize: "90%" }}>
								**Only available with the Trailblazer package
							</p>
							<h3>
								Pick’n’mix from the following content pieces with the Advanced
								and Trailblazer packages:
							</h3>
							<ul style={{ fontWeight: "600" }} className="copy-list ps-md-4 ">
								<li>Blogs</li>
								<li>Case studies </li>
								<li>Brochure</li>
								<li>Newsletter</li>
								<li>Landing page</li>
								<li>Web page refresh</li>
								<li>Updated FAQs</li>
								<li>Email marketing campaign </li>
							</ul>
							<h3>What’s in it for me?</h3>
							<p>
								You will benefit from a{" "}
								<strong>personalised copy strategy</strong> for your business
								that will be broken down into{" "}
								<strong>4 manageable quarterly meetings</strong> to plan and
								implement the copy you need at the time, whether that means
								industry-specific blogs positioning you as a thought leader,
								case studies showcasing how you helped a client overcome a
								particular problem or a landing page to market a new product or
								service.
							</p>
							<h3>Supercharge your business with my Trailblazer package!</h3>
							<p>
								Content is king as they say and these days content can take many
								forms and have a different purpose. With my Trailblazer package
								you get up to 5 hours of additional copy that suits your
								evolving needs, whether you need a last-minute press release, a
								new case study or a new landing page. All you have to do is sign
								up for the Trailblazer package and then sit back and let me take
								care of the rest.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5 py-lg-7">
				<Container>
					<Row className="h-100 g-5 g-lg-4">
						<Col style={{ minHeight: "100%" }} lg={4}>
							<div
								className="w-100 bg-white h-100 p-4 position-relative"
								style={{ boxShadow: "0px 3px 20px #00000029" }}
							>
								<h2 className="pb-0 mb-1 fs-3">ESSENTIAL</h2>
								<hr
									className="w-100 mt-0 pt-0"
									style={{ opacity: 1, backgroundColor: "#C157A1" }}
								/>
								<ul
									style={{ fontWeight: "600" }}
									className="copy-list ps-0  py-4"
								>
									<li>Kick-off Zoom call </li>
									<li>Quarterly copy strategy call </li>
								</ul>
								<div className=" position-absolute d-none d-lg-block bottom-0 pb-4 px-4 w-100 start-50 translate-middle-x">
									<hr
										className="w-100 mt-0 pt-0 mb-4"
										style={{ opacity: 0.7, backgroundColor: "#00000029" }}
									/>
									<p className="fatface py-4 text-center">
										<span className="fs-2">£995</span>
										<span style={{ color: "#3D3D3D76" }} className="fs-4">
											.00/Year
										</span>
									</p>
									<hr
										className="w-100 mt-0 pt-0 mb-4"
										style={{ opacity: 0.7, backgroundColor: "#00000029" }}
									/>
									<p className="fatface py-4 text-center">
										<span className="fs-2">£75</span>
										<span style={{ color: "#3D3D3D76" }} className="fs-4">
											.00/Month
										</span>
									</p>
								</div>
								<div className="d-lg-none">
									<hr
										className="w-100 mt-0 pt-0 mb-4"
										style={{ opacity: 0.7, backgroundColor: "#00000029" }}
									/>
									<p className="fatface py-4 text-center">
										<span className="fs-2">£995</span>
										<span style={{ color: "#3D3D3D76" }} className="fs-4">
											.00/Year
										</span>
									</p>
								</div>
								<div className="d-lg-none">
									<hr
										className="w-100 mt-0 pt-0 mb-4"
										style={{ opacity: 0.7, backgroundColor: "#00000029" }}
									/>
									<p className="fatface py-4 text-center">
										<span className="fs-2">£75</span>
										<span style={{ color: "#3D3D3D76" }} className="fs-4">
											.00/Month
										</span>
									</p>
								</div>
							</div>
						</Col>
						<Col style={{ minHeight: "100%" }} lg={4}>
							<div
								className="w-100 bg-white h-100 p-4 position-relative"
								style={{ boxShadow: "0px 3px 20px #00000029" }}
							>
								<h2 className="pb-0 mb-1 fs-3">ADVANCED</h2>
								<hr
									className="w-100 mt-0 pt-0"
									style={{ opacity: 1, backgroundColor: "#C157A1" }}
								/>
								<ul
									style={{ fontWeight: "600" }}
									className="copy-list ps-0  py-4"
								>
									<li>Kick-off Zoom call</li>
									<li>Quarterly copy strategy call</li>
									<li>1 x copy content/month + 1 social media post</li>
								</ul>
								<div className=" position-absolute d-none d-lg-block bottom-0 pb-4 px-4 w-100 start-50 translate-middle-x">
									<hr
										className="w-100 mt-0 pt-0 mb-4"
										style={{ opacity: 0.7, backgroundColor: "#00000029" }}
									/>
									<p className="fatface py-4 text-center">
										<span className="fs-2">£4495</span>
										<span style={{ color: "#3D3D3D76" }} className="fs-4">
											.00/Year
										</span>
									</p>
									<hr
										className="w-100 mt-0 pt-0 mb-4"
										style={{ opacity: 0.7, backgroundColor: "#00000029" }}
									/>
									<p className="fatface py-4 text-center">
										<span className="fs-2">£395</span>
										<span style={{ color: "#3D3D3D76" }} className="fs-4">
											.00/Month
										</span>
									</p>
								</div>
								<div className="d-lg-none">
									<hr
										className="w-100 mt-0 pt-0 mb-4"
										style={{ opacity: 0.7, backgroundColor: "#00000029" }}
									/>
									<p className="fatface py-4 text-center">
										<span className="fs-2">£4495</span>
										<span style={{ color: "#3D3D3D76" }} className="fs-4">
											.00/Year
										</span>
									</p>
								</div>
								<div className="d-lg-none">
									<hr
										className="w-100 mt-0 pt-0 mb-4"
										style={{ opacity: 0.7, backgroundColor: "#00000029" }}
									/>
									<p className="fatface py-4 text-center">
										<span className="fs-2">£395</span>
										<span style={{ color: "#3D3D3D76" }} className="fs-4">
											.00/Month
										</span>
									</p>
								</div>
							</div>
						</Col>
						<Col style={{ minHeight: "100%" }} lg={4}>
							<div
								className="w-100 bg-white h-100 p-4 position-relative"
								style={{ boxShadow: "0px 3px 20px #00000029" }}
							>
								<h2 className="pb-0 mb-1 fs-3">TRAILBLAZER</h2>
								<hr
									className="w-100 mt-0 pt-0"
									style={{ opacity: 1, backgroundColor: "#C157A1" }}
								/>
								<ul
									style={{ fontWeight: "600" }}
									className="copy-list ps-0  py-4"
								>
									<li>Kick-off Zoom call</li>
									<li>Quarterly copy strategy call</li>
									<li>
										3 x copy content/month + accompanying social media posts{" "}
									</li>
									<li>Up to 5 hours/month to work on ad hoc copy needs</li>
								</ul>

								<div>
									<hr
										className="w-100 mt-0 pt-0 mb-4"
										style={{ opacity: 0.7, backgroundColor: "#00000029" }}
									/>
									<p className="fatface py-4 text-center">
										<span className="fs-2">£14,995</span>
										<span style={{ color: "#3D3D3D76" }} className="fs-4">
											.00/Year
										</span>
									</p>
								</div>
								<div>
									<hr
										className="w-100 mt-0 pt-0 mb-4"
										style={{ opacity: 0.7, backgroundColor: "#00000029" }}
									/>
									<p className="fatface py-4 text-center">
										<span className="fs-2">£1295</span>
										<span style={{ color: "#3D3D3D76" }} className="fs-4">
											.00/Month
										</span>
									</p>
								</div>
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<p className="mt-3" style={{ fontSize: "90%" }}>
								*All prices exclude VAT which will be charged at the rate in
								force on the order date.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<CtaBanner
				headline={<>Click here to book a free 30-minute discovery call</>}
				bgColor="dark-background"
				btnTxt="Contact"
				btnLink="/contact"
			/>
			<section className="py-5 py-lg-7">
				<Container>
					<Row className="h-100 g-5 g-lg-4">
						<Col style={{ minHeight: "100%" }} lg={4}>
							<div
								className="w-100 bg-white h-100 p-4 position-relative"
								style={{ boxShadow: "0px 3px 20px #00000029" }}
							>
								<h2 className="pb-0 mb-1 fs-3">Marketing package</h2>
								<hr
									className="w-100 mt-0 pt-0"
									style={{ opacity: 1, backgroundColor: "#C157A1" }}
								/>
								<ul
									style={{ fontWeight: "600" }}
									className="copy-list ps-0  py-4"
								>
									<li>1 Newsletter</li>
									<li>1 Blog*</li>
									<li>2 x social posts to market the newsletter/blog</li>
								</ul>
								<div className=" position-absolute d-none d-lg-block bottom-0 pb-4 px-4 w-100 start-50 translate-middle-x">
									<hr
										className="w-100 mt-0 pt-0 mb-4"
										style={{ opacity: 0.7, backgroundColor: "#00000029" }}
									/>
									<p className="fatface pb-5 text-center">
										<span className="fs-2">£495</span>
										<span style={{ color: "#3D3D3D76" }} className="fs-4">
											.00
										</span>
									</p>
									<p
										className="mb-0 pb-0 normal-weight"
										style={{ color: "#C157A1", fontSize: "80%" }}
									>
										*One blog up to 1000 words
									</p>
									<p
										className="mb-3 normal-weight"
										style={{ color: "#C157A1", fontSize: "80%" }}
									>
										*One web page is 300-400 words
									</p>
								</div>
								<div className="d-lg-none">
									<hr
										className="w-100 mt-0 pt-0 mb-4"
										style={{ opacity: 0.7, backgroundColor: "#00000029" }}
									/>
									<p className="fatface pb-5 text-center">
										<span className="fs-2">£495</span>
										<span style={{ color: "#3D3D3D76" }} className="fs-4">
											.00
										</span>
									</p>
									<p
										className="mb-0 pb-0 normal-weight"
										style={{ color: "#C157A1", fontSize: "80%" }}
									>
										*One blog up to 1000 words
									</p>
									<p
										className="mb-3 normal-weight"
										style={{ color: "#C157A1", fontSize: "80%" }}
									>
										*One web page is 300-400 words
									</p>
								</div>
							</div>
						</Col>
						<Col style={{ minHeight: "100%" }} lg={4}>
							<div
								className="w-100 bg-white h-100 position-relative p-4"
								style={{ boxShadow: "0px 3px 20px #00000029" }}
							>
								<h2 className="pb-0 mb-1 fs-3">Blog package</h2>
								<hr
									className="w-100 mt-0 pt-0"
									style={{ opacity: 1, backgroundColor: "#C157A1" }}
								/>
								<ul
									style={{ fontWeight: "600" }}
									className="copy-list ps-0  py-4"
								>
									<li>4 x blogs, one a week or monthly, it’s up to you!</li>
								</ul>
								<div className=" d-none d-lg-block position-absolute bottom-0 pb-4 px-4 w-100 start-50 translate-middle-x">
									<hr
										className="w-100 mt-0 pt-0 mb-4"
										style={{ opacity: 0.7, backgroundColor: "#00000029" }}
									/>
									<p className="fatface pb-5 text-center">
										<span className="fs-2">£995</span>
										<span style={{ color: "#3D3D3D76" }} className="fs-4">
											.00
										</span>
									</p>
									<p
										className="mb-0 pb-0 normal-weight"
										style={{ color: "#C157A1", fontSize: "80%" }}
									>
										*One blog up to 1000 words
									</p>
									<p
										className="mb-3 normal-weight"
										style={{ color: "#C157A1", fontSize: "80%" }}
									>
										*One web page is 300-400 words
									</p>
								</div>
								<div className="d-lg-none">
									<hr
										className="w-100 mt-0 pt-0 mb-4"
										style={{ opacity: 0.7, backgroundColor: "#00000029" }}
									/>
									<p className="fatface pb-5 text-center">
										<span className="fs-2">£995</span>
										<span style={{ color: "#3D3D3D76" }} className="fs-4">
											.00
										</span>
									</p>
									<p
										className="mb-0 pb-0 normal-weight"
										style={{ color: "#C157A1", fontSize: "80%" }}
									>
										*One blog up to 1000 words
									</p>
									<p
										className="mb-3 normal-weight"
										style={{ color: "#C157A1", fontSize: "80%" }}
									>
										*One web page is 300-400 words
									</p>
								</div>
							</div>
						</Col>
						<Col style={{ minHeight: "100%" }} lg={4}>
							<div
								className="w-100 bg-white h-100 p-4"
								style={{ boxShadow: "0px 3px 20px #00000029" }}
							>
								<h2 className="pb-0 mb-1 fs-3">Website copy packages</h2>
								<hr
									className="w-100 mt-0 pt-0"
									style={{ opacity: 1, backgroundColor: "#C157A1" }}
								/>
								<ul
									style={{ fontWeight: "600" }}
									className="copy-list ps-0  py-4"
								>
									<li>5 pages* of web copy</li>
								</ul>
								<hr
									className="w-100 mt-0 pt-0 mb-4"
									style={{ opacity: 0.7, backgroundColor: "#00000029" }}
								/>
								<p className="fatface pb-5 text-center">
									<span className="fs-2">£995</span>
									<span style={{ color: "#3D3D3D76" }} className="fs-4">
										.00
									</span>
								</p>
								<ul
									style={{ fontWeight: "600" }}
									className="copy-list ps-0  pb-4"
								>
									<li>10 pages* of website copy</li>
								</ul>
								<hr
									className="w-100 mt-0 pt-0 mb-4"
									style={{ opacity: 0.7, backgroundColor: "#00000029" }}
								/>
								<p className="fatface pb-5 text-center">
									<span className="fs-2">£1895</span>
									<span style={{ color: "#3D3D3D76" }} className="fs-4">
										.00
									</span>
								</p>
								<p
									className="mb-0 pb-0 normal-weight"
									style={{ color: "#C157A1", fontSize: "80%" }}
								>
									*One blog up to 1000 words
								</p>
								<p
									className="mb-3 normal-weight"
									style={{ color: "#C157A1", fontSize: "80%" }}
								>
									*One web page is 300-400 words
								</p>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<CtaBanner
				headline={
					<>
						I'd love to hear more about your
						<br className="d-none d-md-block" /> needs, and how I can help.
					</>
				}
				bgColor="dark-background"
				btnTxt="Contact"
				btnLink="/contact"
			/>
		</Layout>
	);
};

export default PackagesPage;
